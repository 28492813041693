import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';
import Checker from './Checker';

export default function CheckerModal({
  visible,
  magicLinkId,
  email,
  onResend,
}) {
  return (
    <Modal visible={visible} footer={null} closable={false}>
      {magicLinkId && email && (
        <Checker onResend={onResend} email={email} magicLinkId={magicLinkId} />
      )}
    </Modal>
  );
}

CheckerModal.propTypes = {
  email: PropTypes.string,
  magicLinkId: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  onResend: PropTypes.func.isRequired,
};

CheckerModal.defaultProps = {
  magicLinkId: null,
  email: null,
};
