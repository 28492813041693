import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useSWR from 'swr';
import { Button, Space, Spin } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { fetcher, getPath } from '../../api/config';
import StudentModal from './StudentModal';
import StudentList from './StudentList';
import { useOrganizationId } from '../../lib/hooks';

const noop = () => {};

export default function StudentInformation({ onSuccess }) {
  const organizationId = useOrganizationId();
  const { data: students, error, mutate } = useSWR(
    getPath(`/clients/profile/students`, { organizationId }),
    fetcher,
  );

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Spin spinning={!students && !error}>
      <Space style={{ width: '100%' }} direction="vertical">
        {students && students.length > 0 && (
          <div>
            {students && students.length && (
              <StudentList onClick={onSuccess} students={students} />
            )}
          </div>
        )}
        <Button
          type="primary"
          ghost
          icon={<UserAddOutlined />}
          onClick={() => setModalVisible(true)}
        >
          Agregar nuevo alumno
        </Button>
      </Space>
      <StudentModal
        visible={modalVisible}
        onCancel={() => {
          mutate().then(noop).catch(noop);
          setModalVisible(false);
        }}
      />
    </Spin>
  );
}

StudentInformation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
