import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import React from 'react';
import CallToActionButton from '../CallToActionButton';

export default function RecoverPassword({ onSuccess }) {
  return (
    <Form name="recover-password" onFinish={onSuccess}>
      <Form.Item
        name="email"
        extra="Te enviaremos un email con las instrucciones para cambiar tu contraseña"
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input size="large" type="email" placeholder="hola@example.com" />
      </Form.Item>
      <Form.Item className="text-center">
        <CallToActionButton htmlType="submit">
          Enviar instrucciones
        </CallToActionButton>
      </Form.Item>
    </Form>
  );
}

RecoverPassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
