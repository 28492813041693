import { notification, PageHeader, Spin } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import HomeLayout from '../../HomeLayout';
import RecoverPassword from '../Password/RecoverPassword';
import { fetchUrl } from '../../../api/config';

export default function PasswordRecovery() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const sendRecoverPasswordInstructions = (values) => {
    setLoading(true);

    fetchUrl({
      method: 'POST',
      data: { client: values },
      url: '/clients/passwords',
    })
      .then((response) => {
        setLoading(false);
        notification.success({
          message: '¡Listo!',
          description: response.message,
          duration: 0,
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: error.message || 'Ha ocurrido un error al enviar los datos',
        });
      });
  };

  return (
    <HomeLayout>
      <Spin spinning={loading}>
        <PageHeader
          title="Recuperar contraseña"
          onBack={() => history.goBack()}
        />
        <RecoverPassword onSuccess={sendRecoverPasswordInstructions} />
      </Spin>
    </HomeLayout>
  );
}
