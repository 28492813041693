import PropTypes from 'prop-types';
import { Button, List } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';
import humps from 'humps';
import 'moment/locale/es';
import {
  lessonPayloadShape,
  topicsShape,
  tutorsShape,
} from '../lib/prop-types';
import TutorAvatar from './TutorAvatar';
import { filterById, getFullName, timeToObject, isEmpty } from '../lib/helpers';

moment.locale('es');

function formatDuration(duration) {
  if (duration === 0.5) return '30 minutos';
  if (duration === 1.5) return '1 hora y media';
  const hours = duration === 1 ? 'hora' : 'horas';
  return `${duration} ${hours}`;
}

function hideVoucher(voucher) {
  const displayLength = voucher.length <= 3 ? 1 : 3;
  return (
    voucher.substr(0, displayLength) +
    Array(voucher.length - displayLength)
      .fill('#')
      .join('')
  );
}

export default function Confirm({
  data: { topicId, description, date, duration, time: tutorAndTime, vouchers },
  student,
  topics,
  tutors,
  onSubmit,
  isSubmitting,
}) {
  const [tutorId, time] = tutorAndTime.split('|');
  const tutor = tutors.find(filterById(tutorId));
  const topic = topics.find(filterById(topicId));
  const dateFormatted = `${date.format('LL')} a las ${time}`;

  const dataSource = [
    { title: '¿De qué es la tutoría?', value: topic.name },
    { title: '¿Cuál es el temario?', value: description || topic.name },
    { title: '¿Cuándo?', value: dateFormatted },
    { title: '¿Cuánto dura?', value: formatDuration(duration) },
    {
      title: '¿Dónde?',
      value:
        'Online (Video Llamada). Una vez confirmes, te enviaremos las instrucciones por correo',
    },
    {
      title: '¿Con qué profesor?',
      value: (
        <>
          <TutorAvatar firstName={tutor.firstName} src={tutor.avatarUrl} />{' '}
          {getFullName(tutor)}
        </>
      ),
    },
    !isEmpty(student) && {
      title: 'Estudiante',
      value: student.name,
    },
    vouchers && {
      title: 'Pago',
      value: `Pagarás con los vouchers ${vouchers.map(hideVoucher).join(', ')}`,
    },
  ].filter((data) => Boolean(data));

  const submit = () => {
    const startsAt = date.clone().set(timeToObject(time));
    const endsAt = startsAt.clone().add(duration, 'hour');
    onSubmit({
      type: 'submit',
      payload: humps.decamelizeKeys({
        tutorId: parseInt(tutorId, 10),
        topicId,
        scheduledDate: date.format('YYYY-MM-DD'),
        startsAt: startsAt.format(),
        endsAt: endsAt.format(),
        description,
      }),
    });
  };

  return (
    <section>
      <List
        size="small"
        bordered
        dataSource={dataSource}
        renderItem={({ title, value }) => (
          <List.Item>
            <List.Item.Meta title={title} description={value} />
          </List.Item>
        )}
      />
      <div style={{ marginTop: 20 }}>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={() => submit()}
          block
          type="primary"
          icon={<CheckOutlined />}
          size="large"
        >
          {isSubmitting ? 'Agendando...' : 'Agendar Clase'}
        </Button>
      </div>
    </section>
  );
}

Confirm.propTypes = {
  data: lessonPayloadShape.isRequired,
  topics: topicsShape.isRequired,
  tutors: tutorsShape.isRequired,
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

Confirm.defaultProps = {
  student: undefined,
};
