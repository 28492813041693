import { Form, Input, Radio, Select } from 'antd';
import React from 'react';
import { lessonPayloadShape, topicsShape } from '../lib/prop-types';
import ContinueButton from './ContinueButton';
import { useHours } from '../lib/hooks';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

function getInitialTopicId(topics) {
  if (!topics.length) return undefined;
  const topic = topics.find(({ name }) => name.includes('Matemáticas'));
  return (topic || topics[0]).id;
}

export default function FirstStep({ topics, lesson }) {
  const hours = useHours();
  return (
    <Form
      name="step1"
      initialValues={{
        topicId: lesson.topicId || getInitialTopicId(topics),
        duration: lesson.duration || 1,
        description: lesson.description || '',
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="topicId"
        label="¿De qué asignatura quieres que trate la clase?"
      >
        <Select style={{ width: '100%' }}>
          {topics.map((topic) => (
            <Option value={topic.id} key={topic.id}>
              {topic.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="description"
        label="¿Cuál es el temario?"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="duration" label="¿Cuánto quieres que dure la clase?">
        <Radio.Group>
          {hours.map(({ value, label }) => (
            <Radio.Button key={value} value={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <ContinueButton />
    </Form>
  );
}

FirstStep.propTypes = {
  topics: topicsShape.isRequired,
  lesson: lessonPayloadShape.isRequired,
};
