import PropTypes from 'prop-types';
import { Steps } from 'antd';
import React from 'react';
import { lessonPayloadShape } from '../lib/prop-types';
import { useOrganizationId } from '../lib/hooks';
import { DEFAULT_ORG_ID } from './OrganizationProvider';

const { Step } = Steps;

function disabled(data, ...required) {
  const filtered = required.filter((attr) => Boolean(data[attr]));
  return required.length !== filtered.length;
}

export default function StepNavigation({ step, onChange, lesson }) {
  const organizationId = useOrganizationId();
  const commonSteps = [
    <Step key="step-0" title="Información de la clase" />,
    <Step
      key="step-1"
      disabled={disabled(lesson, 'topicId', 'duration')}
      title="Selección de profesor"
    />,
    <Step
      key="step-2"
      disabled={
        'preference' in lesson &&
        lesson.preference &&
        disabled(lesson, 'tutorId')
      }
      title="Selección de horario"
    />,
  ];

  if (organizationId === DEFAULT_ORG_ID) {
    return (
      <Steps size="small" type="navigation" current={step} onChange={onChange}>
        {commonSteps}
        <Step
          disabled={disabled(lesson, 'duration', 'time', 'topicId')}
          title="Vouchers"
        />
        <Step disabled={disabled(lesson, 'vouchers')} title="Estudiante" />
        <Step disabled={disabled(lesson, 'vouchers')} title="Confirmación" />
      </Steps>
    );
  }

  return (
    <Steps size="small" type="navigation" current={step} onChange={onChange}>
      {commonSteps}
      <Step
        disabled={disabled(lesson, 'duration', 'time', 'topicId')}
        title="Confirmación"
      />
    </Steps>
  );
}

StepNavigation.propTypes = {
  lesson: lessonPayloadShape.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};
