import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import React from 'react';
import CallToActionButton from '../CallToActionButton';

export default function LoginForm({ onSuccess }) {
  return (
    <Form name="basic" onFinish={onSuccess}>
      <p className="text-center">Te enviaremos un correo para iniciar sesión</p>
      <Form.Item
        name="email"
        extra="Ingresa el correo al cual te enviamos la cuponera"
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input size="large" type="email" placeholder="hola@example.com" />
      </Form.Item>
      <Form.Item className="text-center">
        <CallToActionButton htmlType="submit">
          Iniciar sesión
        </CallToActionButton>
      </Form.Item>
    </Form>
  );
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
