import { List, Rate } from 'antd';
import React from 'react';
import TutorAvatar from './TutorAvatar';
import { getFullName } from '../lib/helpers';
import { tutorShape } from '../lib/prop-types';

export default function TutorItemMeta({ tutor }) {
  return (
    <List.Item.Meta
      avatar={<TutorAvatar firstName={tutor.firstName} src={tutor.avatarUrl} />}
      title={getFullName(tutor)}
      description={
        <>
          {tutor.averageScore >= 1 ? (
            <>
              <Rate
                allowHalf
                defaultValue={tutor.averageScore}
                disabled
                style={{ fontSize: 14 }}
              />{' '}
              <span style={{ marginLeft: 10 }}>{tutor.averageScore} / 5</span>
            </>
          ) : (
            'Sin calificaciones'
          )}
        </>
      }
    />
  );
}

TutorItemMeta.propTypes = {
  tutor: tutorShape.isRequired,
};
