import PropTypes from 'prop-types';
import React from 'react';
import { Result, Button } from 'antd';

export default function Claimed({ email }) {
  return (
    <Result
      status="success"
      title="Listo!"
      extra={[
        <div key="message">
          <p>
            Iniciaste sesión con tu correo
            <br />
            <strong>{email}</strong>
          </p>
        </div>,
        <Button
          onClick={() => window.location.reload()}
          type="primary"
          key="console"
        >
          Recargar página
        </Button>,
      ]}
    />
  );
}

Claimed.propTypes = {
  email: PropTypes.string.isRequired,
};
