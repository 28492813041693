// eslint-disable-next-line import/prefer-default-export
import { fetchUrl } from './config';

export async function createLesson(organizationId, lesson, vouchers, student) {
  return fetchUrl({
    method: 'POST',
    data: {
      lesson,
      vouchers,
      students: [student.id],
    },
    url: `/clients/organizations/${organizationId}/lessons`,
  });
}

export async function createStudent(organizationId, student) {
  return fetchUrl({
    method: 'POST',
    data: {
      organizationId,
      student,
    },
    url: '/clients/profile/students',
  });
}
