import humps from 'humps';
import ApiError from './error';

const IDENTITY = (x) => x;

const BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:4000'
    : process.env.REACT_APP_API_URL;

function getUrl(path) {
  return `${BASE_URL}/${path.startsWith('/') ? path.slice(1) : path}`;
}

function compactObject(object) {
  if (!object) return undefined;

  const compacted = {};

  Object.keys(object).forEach((key) => {
    if (object[key] !== undefined && object[key] !== null) {
      compacted[key] = object[key];
    }
  });

  return compacted;
}

function getQueryString(params) {
  const queryParams = humps.decamelizeKeys(compactObject(params) || {});
  return Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
}

export function getPath(basePath, params) {
  const queryString = getQueryString(params);
  return params ? `${basePath}?${queryString}` : basePath;
}

function getUrlWithQueryString(path, params) {
  return getUrl(getPath(path, params));
}

// eslint-disable-next-line import/prefer-default-export
export async function fetchUrl({ method, data, params, url, transform } = {}) {
  const options = {
    method: method || 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (options.method !== 'GET') {
    options.body = JSON.stringify(humps.decamelizeKeys(data));
  }

  const result = await fetch(
    getUrlWithQueryString(url, compactObject(params)),
    options,
  );

  if (result.ok) {
    const json = await result.json();
    return (transform || IDENTITY)(humps.camelizeKeys(json));
  }

  const json = await result.json().catch(() => {});
  throw new ApiError(result.status, json);
}

export function buildFetcher(transform = IDENTITY) {
  return (url) => fetchUrl({ method: 'GET', url, transform });
}

export const fetcher = buildFetcher();
