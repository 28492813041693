import PropTypes from 'prop-types';
import { Avatar, Button, List } from 'antd';
import React from 'react';

export default function StudentList({ students, onClick }) {
  return (
    <List
      dataSource={students}
      renderItem={(student) => (
        <List.Item
          key={student.id}
          actions={[
            <Button onClick={() => onClick(student)}>Seleccionar</Button>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar>{student.name[0]}</Avatar>}
            title={student.name}
          />
        </List.Item>
      )}
    />
  );
}

StudentList.propTypes = {
  onClick: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
