// eslint-disable-next-line import/prefer-default-export
export function getFullName({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
}

export function filterById(searchId) {
  return ({ id }) => parseInt(searchId, 10) === parseInt(id, 10);
}

export function keyBy(items, keyAttribute) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[keyAttribute]]: [...(result[item[keyAttribute]] || []), item],
    }),
    {},
  );
}

export function range(from, to) {
  const result = [];
  for (let i = from; i <= to; i += 1) {
    result.push(i);
  }
  return result;
}

export function timeToObject(timeStr) {
  const [hour, minute] = timeStr.split(':');
  return {
    hour: parseInt(hour, 10),
    minute: parseInt(minute, 10),
    second: 0,
  };
}

export function getNextQuarter(date) {
  if (date.minute() % 15 === 0) {
    return date.clone();
  }

  const roundedMinutes = Math.ceil(date.minute() / 15) * 15;
  return date.clone().minute(roundedMinutes);
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
export function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function omit(obj, ...omitKeys) {
  const copy = { ...obj };
  omitKeys.forEach((omitKey) => {
    delete copy[omitKey];
  });
  return copy;
}
