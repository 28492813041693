import moment from 'moment';
import useSWR from 'swr';
import { useContext } from 'react';
import { buildFetcher, getPath } from '../api/config';
import { getNextQuarter, keyBy } from './helpers';
import {
  DEFAULT_ORG_ID,
  OrganizationContext,
} from '../components/OrganizationProvider';
import { isRangeDisabled } from './local-exceptions';

const fetcher = buildFetcher();

export function useOrganizationId() {
  return useContext(OrganizationContext);
}

export function useIsDefaultOrganization() {
  return useOrganizationId() === DEFAULT_ORG_ID;
}

export function useTopicsEffect() {
  const organizationId = useOrganizationId();
  const { data: topics } = useSWR(
    `/organizations/${organizationId}/topics`,
    fetcher,
  );
  return [topics || [], !topics];
}

const steps = {
  basic: 'basic',
  teacher: 'teacher',
  schedule: 'schedule',
  student: 'student',
  payment: 'payment',
  confirmation: 'confirmation',
};

const headers = {
  [steps.basic]: 'Toma de tutoría',
  [steps.teacher]: 'Selección de profesor',
  [steps.schedule]: 'Selección de Horario',
  [steps.student]: 'Datos de estudiante',
  [steps.payment]: 'Ingreso de vouchers',
  [steps.confirmation]: 'Confirmación',
};

function buildStep(stepKey, value) {
  return {
    [stepKey]: {
      value,
      title: headers[stepKey],
    },
  };
}

export function useSteps() {
  const organizationId = useOrganizationId();

  if (organizationId === DEFAULT_ORG_ID) {
    return {
      ...buildStep(steps.basic, 0),
      ...buildStep(steps.teacher, 1),
      ...buildStep(steps.schedule, 2),
      ...buildStep(steps.payment, 3),
      ...buildStep(steps.student, 4),
      ...buildStep(steps.confirmation, 5),
    };
  }

  return {
    ...buildStep(steps.basic, 0),
    ...buildStep(steps.teacher, 1),
    ...buildStep(steps.schedule, 2),
    // step = -1 never gonna happen
    ...buildStep(steps.payment, -1),
    ...buildStep(steps.student, -1),
    ...buildStep(steps.confirmation, 3),
  };
}

export function useHours() {
  const organizationId = useOrganizationId();

  if (organizationId === DEFAULT_ORG_ID) {
    return [
      { value: 0.5, label: '30 minutos' },
      { value: 1, label: '1 hora' },
      { value: 1.5, label: '1 hora y media' },
      { value: 2, label: '2 horas' },
    ];
  }

  return [
    { value: 1, label: '1 hora' },
    { value: 2, label: '2 horas' },
  ];
}

export function useTutorsEffect(topicId) {
  const organizationId = useOrganizationId();
  const { data: tutors } = useSWR(
    getPath(`/organizations/${organizationId}/tutors`, { topicId }),
    fetcher,
  );
  return [tutors || [], !tutors];
}

function toFromMoment(from) {
  const fromMoment = moment(from);
  const minFromMoment = getNextQuarter(moment().add(8, 'hour'));

  if (minFromMoment > fromMoment) {
    return minFromMoment;
  }

  return fromMoment;
}

function isInExceptions({ from, to }, exceptions) {
  if (isRangeDisabled(from, to)) return true;

  return !!exceptions.find((exception) => {
    return moment(exception.from) < to && moment(exception.to) > from;
  });
}

function expandAvailability({ from, to, duration }, exceptions) {
  const fromMoment = toFromMoment(from);
  const toMoment = moment(to).subtract(duration, 'hours');
  const expanded = [];

  while (fromMoment <= toMoment) {
    const availability = {
      from: fromMoment,
      to: fromMoment.clone().add(duration, 'hours'),
    };

    if (!isInExceptions(availability, exceptions)) {
      expanded.push({
        from: availability.from.format('HH:mm'),
        to: availability.to.format('HH:mm'),
      });
    }

    fromMoment.add(15, 'minutes');
  }

  return expanded;
}

function availabilitiesToSchedule(
  availabilities,
  exceptions,
  duration,
  selectedTutorId,
) {
  const byTutor = {};
  const selectedAvailabilities = selectedTutorId
    ? availabilities.filter(({ tutorId }) => tutorId === selectedTutorId)
    : availabilities;

  const exceptionsByTutor = keyBy(exceptions, 'tutorId');

  selectedAvailabilities.forEach(({ tutorId, ...range }) => {
    const tutorAvailability = byTutor[tutorId] || [];
    const expanded = tutorAvailability.concat(
      expandAvailability(
        { ...range, duration },
        exceptionsByTutor[tutorId] || [],
      ),
    );
    // Expanded availability could be empty when the tutor has an available window
    // smaller than the duration. Example: available from 13:00 to 14:00 but the duration
    // is 2 hours length
    if (expanded.length) {
      byTutor[tutorId] = expanded;
    }
  });

  return byTutor;
}

function availabilitiesTransformer(duration, selectedTutor) {
  return ({ availabilities, exceptions }) =>
    availabilitiesToSchedule(
      availabilities,
      exceptions,
      duration,
      selectedTutor,
    );
}

export function useScheduledHours(topicId, date, duration, tutorId) {
  const { data: schedule } = useSWR(
    getPath(`/topics/${topicId}/availability`, { date, tutorId }),
    buildFetcher(availabilitiesTransformer(duration, tutorId)),
  );

  return [schedule || {}, !schedule];
}
