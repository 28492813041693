import PropTypes from 'prop-types';
import { Button } from 'antd';
import React from 'react';

export default function CallToActionButton({ children, ...rest }) {
  return (
    <Button
      block
      size="large"
      shape="round"
      type="primary"
      style={{ backgroundColor: '#5538C8', borderColor: '#5538C8' }}
      {...rest}
    >
      {children}
    </Button>
  );
}

CallToActionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};
