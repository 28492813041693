import PropTypes from 'prop-types';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../api/config';
import Pending from './Pending';
import Claimed from './Claimed';
import Expired from './Expired';

export default function Checker({ magicLinkId, email, onResend }) {
  const {
    data: { claimed, expired },
    error: unauthorized,
  } = useSWR(`/passwordless_sessions/${magicLinkId}/check`, fetcher, {
    refreshInterval: 3000,
    initialData: { claimed: false, expired: false },
  });

  if (claimed) {
    return <Claimed email={email} />;
  }

  if (expired || unauthorized) {
    return <Expired email={email} onResend={onResend} />;
  }

  return <Pending email={email} onResend={onResend} />;
}

Checker.propTypes = {
  magicLinkId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onResend: PropTypes.func.isRequired,
};
