import PropTypes from 'prop-types';
import { Button, Form, List, Switch } from 'antd';
import React, { useState } from 'react';
import { tutorsShape } from '../lib/prop-types';
import TutorItemMeta from './TutorItemMeta';
import ContinueButton from './ContinueButton';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function SecondStep({ tutors, isLoading, preference }) {
  const [form] = Form.useForm();
  const [currentPreference, setCurrentPreference] = useState(preference);

  return (
    <Form
      form={form}
      name="step2"
      initialValues={{ preference, tutorId: null }}
      onValuesChange={(changedValues, values) => {
        setCurrentPreference(values.preference);
      }}
    >
      <Form.Item
        {...formItemLayout}
        valuePropName="checked"
        name="preference"
        label="¿Quieres tomar clases con un profesor en particular?"
      >
        <Switch
          checkedChildren="Con preferencia"
          unCheckedChildren="Sin Preferencia"
        />
      </Form.Item>
      {currentPreference ? (
        <List
          loading={isLoading}
          dataSource={tutors}
          renderItem={(tutor) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => {
                    form.setFieldsValue({ tutorId: tutor.id });
                    form.submit();
                  }}
                >
                  Seleccionar
                </Button>,
              ]}
            >
              <TutorItemMeta tutor={tutor} />
            </List.Item>
          )}
        />
      ) : (
        <ContinueButton />
      )}
    </Form>
  );
}

SecondStep.propTypes = {
  tutors: tutorsShape.isRequired,
  isLoading: PropTypes.bool.isRequired,
  preference: PropTypes.bool.isRequired,
};
