import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIsDefaultOrganization } from '../../lib/hooks';
import LoginScreen from './Screen';

export default function Login({ children }) {
  const isDefaultOrg = useIsDefaultOrganization();
  const [loggedIn, setLoggedIn] = useState(false);

  if (!isDefaultOrg || loggedIn) return children;

  return <LoginScreen onSuccess={() => setLoggedIn(true)} />;
}

Login.propTypes = {
  children: PropTypes.element.isRequired,
};
