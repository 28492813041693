import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

export const lessonPayloadShape = PropTypes.shape({
  topic: PropTypes.string,
  description: PropTypes.string,
  date: momentPropTypes.momentObj,
  duration: PropTypes.number,
  tutorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vouchers: PropTypes.arrayOf(PropTypes.string),
});

export const topicsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

export const tutorShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  averageScore: PropTypes.number.isRequired,
});

export const tutorsShape = PropTypes.arrayOf(tutorShape);
