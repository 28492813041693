import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import React from 'react';

const PASSWORD_RULES = [
  {
    required: true,
    message: 'Debes ingresar una contraseña',
  },
];

const CONFIRMATION_RULES = [
  PASSWORD_RULES[0],
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('No coincide con la contraseña'));
    },
  }),
];

export default function NewPasswordForm({ onSuccess }) {
  return (
    <Form name="new-password" onFinish={onSuccess}>
      <Form.Item
        name="password"
        label="Contraseña"
        rules={PASSWORD_RULES}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        label="Confirma tu contraseña"
        dependencies={['password']}
        hasFeedback
        rules={CONFIRMATION_RULES}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Guardar nueva contraseña
        </Button>
      </Form.Item>
    </Form>
  );
}

NewPasswordForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
