import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import React from 'react';

export default function TutorAvatar({ src, firstName }) {
  if (src) return <Avatar src={src} />;
  return <Avatar>{firstName[0]}</Avatar>;
}

TutorAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  src: PropTypes.string,
};

TutorAvatar.defaultProps = {
  src: null,
};
