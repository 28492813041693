import PropTypes from 'prop-types';
import { Select } from 'antd';
import React from 'react';

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export default function EstablishmentSelect({ establishments, type, ...rest }) {
  const options = (establishments || [])
    .filter((establishment) => establishment.type === type)
    .map(({ name: label, id: value }) => ({ label, value }));
  return (
    <Select
      {...rest}
      options={options}
      style={{ width: '100%' }}
      showSearch
      filterOption={filterOption}
    >
      {(establishments || [])
        .filter((establishment) => establishment.type === type)
        .map(({ name: label, id: value }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
    </Select>
  );
}

EstablishmentSelect.propTypes = {
  establishments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['School', 'University']).isRequired,
};
