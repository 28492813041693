import PropTypes from 'prop-types';
import { Alert, Form, Space, Spin } from 'antd';
import React from 'react';
import useSWR from 'swr';
import ContinueButton from './ContinueButton';
import { fetcher, getPath } from '../api/config';

export default function Payment({ onSuccess, duration }) {
  const [form] = Form.useForm();
  const vouchersCount = duration * 2;
  const vouchersLabel = vouchersCount === 1 ? 'voucher' : 'vouchers';

  const { data: vouchers, error } = useSWR(
    getPath('/clients/profile/vouchers', { duration }),
    fetcher,
  );

  const loading = !vouchers && !error;

  const onFinish = async () => {
    onSuccess(vouchers.map((voucher) => voucher.token));
  };

  return (
    <Form form={form} name="payment" onFinish={onFinish}>
      <Spin tip="Obteniendo vouchers..." spinning={loading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {vouchers && vouchers.length === 0 && (
            <Alert
              message={
                <>
                  Al parecer no te quedan vouchers. Puedes comprar más en{' '}
                  <a href="https://www.sacateun7.cl">www.sacateun7.cl</a>
                </>
              }
              type="warning"
              showIcon
            />
          )}
          {vouchers && vouchers.length > 0 && (
            <>
              <p>
                Vamos a usar {vouchersCount} {vouchersLabel} de tu cuponera al
                agendar la clase.
              </p>
              <p>
                {vouchersCount === 1
                  ? 'El voucher a usar es el siguiente:'
                  : 'Los vouchers a usar son los siguientes'}
              </p>
              {vouchers && (
                <ul>
                  {vouchers.map((voucher) => (
                    <li key={voucher.id}>{voucher.token}</li>
                  ))}
                </ul>
              )}
            </>
          )}
          {Boolean(error) && (
            <Alert
              message="No pudimos obtener tus vouchers."
              showIcon
              type="warning"
            />
          )}
          <ContinueButton
            disabled={loading || Boolean(error)}
            loading={loading}
          />
        </Space>
      </Spin>
    </Form>
  );
}

Payment.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};
