import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  notification,
  Layout,
  PageHeader,
  Result,
  Spin,
  message,
} from 'antd';
import humps from 'humps';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import Confirm from './Confirm';
import {
  useSteps,
  useTopicsEffect,
  useTutorsEffect,
  useOrganizationId,
} from '../lib/hooks';
import Payment from './Payment';
import StepNavigation from './StepsNavigation';
import StudentInformation from './StudentInformation';
import Lesson from './Lesson';
import { timeToObject } from '../lib/helpers';
import { createLesson } from '../api';
import { DEFAULT_ORG_ID } from './OrganizationProvider';

const fieldsByStep = {
  /**
   * tutorId value is set with setFieldsValue in the step-2 form.
   * For some reason, when this form is submitted, the tutorId value
   * is not present in the "values" property but can be retrieved
   * using getFieldsValue or getFieldValue (so it is present in the form)
   * Reference:
   * - https://ant.design/components/form/#FormInstance
   * - https://ant.design/components/form/#Form.Provider
   */
  step2: ['preference', 'tutorId'],
};

function transformToLessonPayload({
  topicId,
  description,
  date,
  duration,
  time: tutorAndTime,
}) {
  const [tutorId, time] = tutorAndTime.split('|');
  const startsAt = date.clone().set(timeToObject(time));
  const endsAt = startsAt.clone().add(duration, 'hour');

  return humps.decamelizeKeys({
    tutorId: parseInt(tutorId, 10),
    topicId,
    scheduledDate: date.format('YYYY-MM-DD'),
    startsAt: startsAt.format(),
    endsAt: endsAt.format(),
    description,
  });
}

function findTitle(steps, currentStep) {
  const activeKey = Object.keys(steps).find(
    (stepKey) => steps[stepKey].value === currentStep,
  );
  return steps[activeKey].title;
}

function Scheduler() {
  const [lesson, setLesson] = useState({ preference: false });
  const [student, setStudent] = useState({});
  const [scheduledLesson, setScheduledLesson] = useState(null);
  const [vouchers, setVouchers] = useState([]);
  const [step, setStep] = useState(0);
  const [topics, isLoading] = useTopicsEffect();
  const [tutors, areTutorsLoading] = useTutorsEffect(lesson.topicId);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const organizationId = useOrganizationId();
  const steps = useSteps();

  const onStepFinish = (name, { values, forms }) => {
    if (name === 'payment' || name === 'student-information') {
      return;
    }

    const newValues = fieldsByStep[name]
      ? forms[name].getFieldsValue(fieldsByStep[name])
      : values;
    if (values.topicId) {
      // Changing topic changes everything else (tutors, tutors availability, etc)
      setLesson(newValues);
    } else {
      setLesson((payload) => ({ ...payload, ...newValues }));
    }
    setStep((curr) => curr + 1);
  };

  const onPaymentSuccess = (newVouchers) => {
    setVouchers(newVouchers);
    setStep((curr) => curr + 1);
  };

  const onStudentDataSuccess = (studentData) => {
    setStudent(studentData);
    setStep((curr) => curr + 1);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const lessonPayload = transformToLessonPayload(lesson);

    if (organizationId !== DEFAULT_ORG_ID) {
      window.parent.postMessage(
        {
          type: 'submit',
          payload: lessonPayload,
        },
        '*',
      );
      return;
    }

    createLesson(organizationId, lessonPayload, vouchers, student)
      .then((response) => {
        setScheduledLesson(response);
        setSuccess(true);
      })
      .catch((error) => {
        setSubmitting(false);
        message.error(error.message || 'La clase no pudo ser agendada.');
      });
  };

  useEffect(() => {
    const onMessage = ({ data }) => {
      setSubmitting(false);

      if (!data) return;

      if (data.type === 'success') {
        setSuccess(true);
        return;
      }

      if (data.type !== 'error') return;

      notification.error({
        message: 'Ups!',
        description:
          (data.payload && data.payload.message) ||
          'No pudimos agendar la clase.',
      });
    };

    window.addEventListener('message', onMessage, false);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  if (success) {
    if (organizationId === DEFAULT_ORG_ID) {
      return (
        <Result
          style={{ margin: '0 auto' }}
          status="success"
          title="¡Tu clase fue agendada!"
          subTitle="Te enviamos un correo con la información de la clase."
          extra={<Lesson lesson={scheduledLesson} />}
        />
      );
    }

    return (
      <Result
        status="success"
        title="¡Tu clase fue agendada!"
        subTitle="Redirigiendo al detalle de la clase..."
      />
    );
  }

  return (
    <Layout>
      <header style={{ marginBottom: 16, backgroundColor: 'white' }}>
        <StepNavigation lesson={lesson} onChange={setStep} step={step} />
      </header>
      <Layout.Content>
        <Card style={{ maxWidth: '700px', margin: '0 auto' }}>
          <PageHeader
            title={findTitle(steps, step)}
            onBack={step > 0 ? () => setStep((curr) => curr - 1) : undefined}
          />
          <section>
            {isLoading ? (
              <Spin />
            ) : (
              <Form.Provider onFormFinish={onStepFinish}>
                {step === steps.basic.value && (
                  <FirstStep lesson={lesson} topics={topics} />
                )}
                {step === steps.teacher.value && (
                  <SecondStep
                    preference={Boolean(lesson.preference)}
                    isLoading={areTutorsLoading}
                    tutors={tutors}
                  />
                )}
                {step === steps.schedule.value && (
                  <ThirdStep tutors={tutors} data={lesson} />
                )}
                {step === steps.payment.value && (
                  <Payment
                    onSuccess={onPaymentSuccess}
                    duration={lesson.duration || 1}
                    vouchers={vouchers}
                  />
                )}
                {step === steps.student.value && (
                  <StudentInformation
                    student={student}
                    onSuccess={onStudentDataSuccess}
                  />
                )}
                {step === steps.confirmation.value && (
                  <Confirm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                    data={lesson}
                    tutors={tutors}
                    topics={topics}
                    student={student}
                  />
                )}
              </Form.Provider>
            )}
          </section>
        </Card>
      </Layout.Content>
    </Layout>
  );
}

export default Scheduler;
