import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import queryString from 'query-string';

export const DEFAULT_ORG_ID = 'sacateun7';
export const OrganizationContext = createContext(DEFAULT_ORG_ID);

export default function OrganizationProvider({ children }) {
  const { orgId } = queryString.parse(window.location.search);
  const [organizationId] = useState(orgId || DEFAULT_ORG_ID);
  return (
    <OrganizationContext.Provider value={organizationId}>
      {children}
    </OrganizationContext.Provider>
  );
}

OrganizationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
