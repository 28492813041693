import PropTypes from 'prop-types';
import React from 'react';
import { Button, Result } from 'antd';

export default function Expired({ email, onResend }) {
  return (
    <Result
      status="warning"
      title="El enlace expiró"
      extra={[
        <div key="message">
          <p>
            Expiró el enlace para iniciar sesión que enviamos a<br />
            <strong>{email}</strong>
          </p>
        </div>,
        <Button
          onClick={() => onResend({ email })}
          type="primary"
          key="console"
        >
          Reenviar
        </Button>,
        <Button onClick={() => window.location.reload()} key="console">
          Cerrar
        </Button>,
      ]}
    />
  );
}

Expired.propTypes = {
  email: PropTypes.string.isRequired,
  onResend: PropTypes.func.isRequired,
};
