import PropTypes from 'prop-types';
import { Select } from 'antd';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../api/config';

const filterOption = (input, option) =>
  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export default function CareerSelect({ establishmentId, ...rest }) {
  const { data: careers } = useSWR(
    `/guest/establishments/${establishmentId}/careers`,
    fetcher,
  );

  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      showSearch
      filterOption={filterOption}
      options={(careers || []).map(({ name: label, id: value }) => ({
        label,
        value,
      }))}
    />
  );
}

CareerSelect.propTypes = {
  establishmentId: PropTypes.number.isRequired,
};
