import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useSWR from 'swr';
import { Divider, Form, Input, Select } from 'antd';
import { fetcher } from '../../api/config';
import schoolYears from '../../lib/school-years';
import CareerSelect from './CareerSelect';
import EstablishmentSelect from './EstablishmentSelect';
import { omit } from '../../lib/helpers';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const required = [
  {
    required: true,
    message: 'Requerido',
  },
];

const genderOptions = [
  { label: 'Hombre', value: 'male' },
  { label: 'Mujer', value: 'female' },
  { label: 'Otro', value: 'other' },
];

export default function StudentInformation({ form, onSuccess }) {
  const { data: establishmentsData } = useSWR(`/guest/establishments`, fetcher);
  const establishments = establishmentsData || [];
  const [currentTab] = useState('School');
  const [currentUniversityId, setUniversityId] = useState(null);

  const onFinish = ({ universityId, schoolId, ...rest }) => {
    const studentData =
      currentTab === 'School'
        ? {
            establishmentId: schoolId,
            ...omit(rest, 'careerId', 'universityEntryYear'),
          }
        : {
            establishmentId: universityId,
            ...omit(rest, 'schoolYear'),
          };

    onSuccess(studentData);
  };

  const onValuesChange = ({ universityId }) => {
    if (universityId) setUniversityId(universityId);
  };

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      name="student-information"
    >
      <Form.Item
        {...formItemLayout}
        name="firstName"
        label="Nombre"
        rules={required}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="lastName"
        label="Apellido"
        rules={required}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="email"
        label="Email"
        rules={required}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="gender"
        label="Género"
        rules={required}
      >
        <Select style={{ width: '100%' }} options={genderOptions} />
      </Form.Item>
      <Divider orientation="left">Información Académica</Divider>
      {currentTab === 'School' && (
        <>
          <Form.Item
            {...formItemLayout}
            name="schoolId"
            label="Establecimiento"
            rules={currentTab === 'School' && required}
          >
            <EstablishmentSelect
              establishments={establishments}
              type="School"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="schoolYear"
            label="Curso Actual"
            rules={currentTab === 'School' && required}
          >
            <Select
              style={{ width: '100%' }}
              options={schoolYears.map((name) => ({
                label: name,
                value: name,
              }))}
            />
          </Form.Item>
        </>
      )}
      {currentTab === 'University' && (
        <>
          <Form.Item
            {...formItemLayout}
            name="universityId"
            label="Universidad"
            rules={currentTab === 'University' && required}
          >
            <EstablishmentSelect
              establishments={establishments}
              type="University"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="careerId"
            label="Carrera"
            rules={currentTab === 'University' && required}
          >
            {currentUniversityId && (
              <CareerSelect establishmentId={currentUniversityId} />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="universityEntryYear"
            label="Año de ingreso universitario"
            rules={currentTab === 'University' && required}
          >
            <Input type="number" />
          </Form.Item>
        </>
      )}
    </Form>
  );
}

StudentInformation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
};
