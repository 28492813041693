import { Button, Divider, notification, PageHeader, Spin } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PasswordForm from './Form';
import { fetchUrl } from '../../../api/config';
import HomeLayout from '../../HomeLayout';

export default function LoginWithPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const createSession = (values) => {
    setLoading(true);

    fetchUrl({
      method: 'POST',
      data: { session: values },
      url: '/clients/sessions',
    })
      .then((response) => {
        notification.success({ message: response.message });
        history.replace('/');
      })
      .catch((error) => {
        notification.error({ message: error.message });
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <HomeLayout>
      <Spin spinning={loading}>
        <PageHeader title="Iniciar sesión con contraseña" />
        <PasswordForm onSuccess={createSession} />
        <Divider />
        <div className="text-center">
          <Link to="/password-recovery">
            <Button type="link">¿Olvidaste tu contraseña?</Button>
          </Link>
        </div>
      </Spin>
    </HomeLayout>
  );
}
