import queryString from 'query-string';

const { disabled, opening, closing, startDate, endDate } = queryString.parse(
  window.location.search,
);

function getRestrictedDays() {
  try {
    return disabled ? JSON.parse(decodeURIComponent(disabled)) : [];
  } catch (e) {
    return [];
  }
}

const disabledDays = getRestrictedDays();

export function isDayDisabled(day) {
  if (startDate && endDate && (day < startDate || endDate < day)) {
    return true;
  }

  return disabledDays.includes(day);
}

export function isRangeDisabled(from, to) {
  if (!opening || !closing) return false;

  // TODO: refactor exceptions
  const isSaturday = to.weekday() === 5;
  if (isSaturday && to.get('hours') > 15) {
    return true;
  }

  return from.format('HH:mm') < opening || closing < to.format('HH:mm');
}
