import { DatePicker, Form, List, Radio } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { lessonPayloadShape, tutorsShape } from '../lib/prop-types';
import { useIsDefaultOrganization, useScheduledHours } from '../lib/hooks';
import TutorItemMeta from './TutorItemMeta';
import { filterById } from '../lib/helpers';
import { isDayDisabled } from '../lib/local-exceptions';

const DAY_FORMAT = 'YYYY-MM-DD';

export default function ThirdStep({ data, tutors }) {
  const [form] = Form.useForm();
  const { topicId, date, duration, tutorId: selectedTutorId } = data;
  const [currentDate, setCurrentDate] = useState(date || moment());
  const isPreuGauss = !useIsDefaultOrganization();
  const [schedule, loading] = useScheduledHours(
    topicId,
    currentDate.format(DAY_FORMAT),
    duration,
    selectedTutorId,
  );

  const disabledDate = (currentMoment) => {
    if (!isPreuGauss) return false;

    const currentDay = currentMoment.format('YYYY-MM-DD');
    const isPast = currentDay < moment().format('YYYY-MM-DD');
    const isSunday = currentMoment.weekday() === 6;
    const isBanned = isDayDisabled(currentDay);
    return isPast || isSunday || isBanned;
  };

  return (
    <Form
      name="step3"
      form={form}
      initialValues={{ date: currentDate, time: data.time }}
      onValuesChange={(changedValues) => {
        if ('date' in changedValues) setCurrentDate(changedValues.date);
        if ('time' in changedValues) form.submit();
      }}
    >
      <Form.Item name="date" label="Día de la tutoría">
        <DatePicker allowClear={false} disabledDate={disabledDate} />
      </Form.Item>
      <List
        loading={loading}
        itemLayout="vertical"
        dataSource={Object.keys(schedule)}
        renderItem={(tutorId) => {
          const tutor = tutors.find(filterById(tutorId));
          return (
            <List.Item>
              <TutorItemMeta tutor={tutor} />
              <Form.Item name="time">
                <Radio.Group buttonStyle="solid">
                  {schedule[tutorId].map(({ from, to }) => (
                    <Radio.Button
                      key={from}
                      value={`${tutorId}|${from}`}
                      style={{ width: 130 }}
                    >
                      {`${from} - ${to}`}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </List.Item>
          );
        }}
      />
    </Form>
  );
}

ThirdStep.propTypes = {
  data: lessonPayloadShape.isRequired,
  tutors: tutorsShape.isRequired,
};
