import React from 'react';
import Login from './Authentication';
import Scheduler from './Scheduler';

export default function App() {
  return (
    <Login>
      <Scheduler />
    </Login>
  );
}
