import React from 'react';
import { Descriptions } from 'antd';
import {
  ReadOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import TutorAvatar from './TutorAvatar';
import { getFullName } from '../lib/helpers';

export default function Lesson({ lesson }) {
  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item
        label={
          <span>
            <ReadOutlined /> Asignatura
          </span>
        }
      >
        {lesson.topic.name}
      </Descriptions.Item>
      <Descriptions
        label={
          <span>
            <SmileOutlined /> Tutor
          </span>
        }
      >
        <TutorAvatar firstName={lesson.tutor.firstName} />{' '}
        {getFullName(lesson.tutor)}
      </Descriptions>
      <Descriptions.Item
        label={
          <span>
            <ProfileOutlined /> Temario
          </span>
        }
      >
        {lesson.description || 'No hay información ingresada'}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span>
            <ClockCircleOutlined /> Fecha y hora
          </span>
        }
      >
        {moment(lesson.startsAt).format('LLLL')}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span>
            <HourglassOutlined /> Duración
          </span>
        }
      >
        {moment(lesson.endsAt).diff(lesson.startsAt, 'minutes')} minutos
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span>
            <VideoCameraOutlined /> Información para ingresar
          </span>
        }
      >
        Online (Video Llamada). Te enviamos las instrucciones para entrar por
        correo.
      </Descriptions.Item>
    </Descriptions>
  );
}

Lesson.propTypes = {
  lesson: PropTypes.shape({
    description: PropTypes.string.isRequired,
    startsAt: PropTypes.string.isRequired,
    endsAt: PropTypes.string.isRequired,
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    tutor: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
