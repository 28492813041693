import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import React from 'react';
import CallToActionButton from '../CallToActionButton';

export default function PasswordForm({ onSuccess }) {
  return (
    <Form name="password-form" onFinish={onSuccess}>
      <Form.Item
        name="email"
        extra="Ingresa el correo al cual te enviamos la cuponera"
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input size="large" type="email" placeholder="hola@example.com" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input size="large" type="password" placeholder="Tu contraseña" />
      </Form.Item>
      <Form.Item className="text-center">
        <CallToActionButton htmlType="submit">
          Iniciar sesión
        </CallToActionButton>
      </Form.Item>
    </Form>
  );
}

PasswordForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
