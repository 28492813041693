import React, { useState } from 'react';
import { notification, PageHeader, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import NewPasswordForm from './Form';
import { fetchUrl } from '../../../api/config';
import Logo from '../../Logo';
import HomeLayout from '../../HomeLayout';

export default function NewPassword() {
  const { token } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const updatePassword = (values) => {
    setLoading(true);
    const data = {
      client: { ...values, reset_password_token: token },
    };

    fetchUrl({ method: 'PUT', data, url: '/clients/passwords' })
      .then((response) => {
        setLoading(false);
        notification.success({
          message: '¡Listo!',
          description: response.message,
        });
        history.replace('/login');
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error al enviar los datos',
        });
      });
  };

  return (
    <HomeLayout>
      <Logo />
      <Spin spinning={isLoading}>
        <PageHeader
          title="Ingresa tu nueva contraseña"
          onBack={() => history.replace('/')}
        />
        <NewPasswordForm onSuccess={updatePassword} />
      </Spin>
    </HomeLayout>
  );
}
