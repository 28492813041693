import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, message, Modal, notification, Spin } from 'antd';
import StudentForm from './StudentForm';
import { createStudent } from '../../api';
import { useOrganizationId } from '../../lib/hooks';

export default function StudentModal({ visible, onCancel }) {
  const [form] = Form.useForm();
  const organizationId = useOrganizationId();
  const [creatingStudent, setCreatingStudent] = useState(false);

  const handleOnSuccess = async (student) => {
    try {
      setCreatingStudent(true);
      await createStudent(organizationId, student);
      onCancel();
      form.resetFields();
      notification.success({
        message: 'Agregamos al estudiante a tu cuenta',
      });
    } catch (error) {
      message.error(error.message || 'No pudimos crear al alumno');
    } finally {
      setCreatingStudent(false);
    }
  };

  return (
    <Modal
      onCancel={onCancel}
      closable={!creatingStudent}
      title="Nuevo alumno/a"
      visible={visible}
      footer={
        <Button
          disabled={creatingStudent}
          onClick={() => form.submit()}
          type="primary"
        >
          Guardar
        </Button>
      }
    >
      <Spin spinning={creatingStudent}>
        <StudentForm form={form} onSuccess={handleOnSuccess} />
      </Spin>
    </Modal>
  );
}

StudentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
