import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import React from 'react';

export default function ContinueButton({ loading, disabled, ...props }) {
  return (
    <Form.Item {...props}>
      <Button
        block
        disabled={disabled}
        loading={loading}
        type="primary"
        size="large"
        htmlType="submit"
      >
        Continuar
      </Button>
    </Form.Item>
  );
}

ContinueButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

ContinueButton.defaultProps = {
  loading: false,
  disabled: false,
};
