import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import OrganizationProvider from './components/OrganizationProvider';
import NewPassword from './components/Authentication/NewPassword';
import LoginWithPassword from './components/Authentication/Password';
import PasswordRecovery from './components/Authentication/PasswordRecovery';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <OrganizationProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={App} />
            <Route path="/login" exact component={LoginWithPassword} />
            <Route
              path="/password-recovery"
              exact
              component={PasswordRecovery}
            />
            <Route path="/new-password/:token" component={NewPassword} />
          </Switch>
        </Router>
      </OrganizationProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
