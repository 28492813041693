export default [
  '1º Básico',
  '2º Básico',
  '3º Básico',
  '4º Básico',
  '5º Básico',
  '6º Básico',
  '7º Básico',
  '8º Básico',
  '1º Medio',
  '2º Medio',
  '3º Medio',
  '4º Medio',
  'Egresado',
];
