import PropTypes from 'prop-types';
import React from 'react';
import { Result } from 'antd';
import { MailTwoTone } from '@ant-design/icons';

export default function Pending({ email }) {
  return (
    <Result
      icon={<MailTwoTone twoToneColor="#52c41a" />}
      title="Revisa tu correo"
      extra={
        <div>
          <p>
            Te enviamos un correo a
            <br />
            <strong>{email}</strong>
          </p>
          <p>
            Haz clic en el enlace que te enviamos para iniciar sesión en esta
            pestaña.
          </p>
        </div>
      }
    />
  );
}

Pending.propTypes = {
  email: PropTypes.string.isRequired,
};
