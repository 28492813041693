import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Spin,
  notification,
  Result,
  Space,
  Typography,
  Divider,
  Button,
} from 'antd';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { fetcher, fetchUrl } from '../../api/config';
import LoginForm from './MagicLink/Form';
import CheckerModal from './MagicLink/Modal';
import HomeLayout from '../HomeLayout';
import CallToActionButton from './CallToActionButton';
import Logo from '../Logo';

function showGenericError() {
  notification.error({
    duration: 0,
    message: 'No pudimos enviarte el correo para iniciar sesión',
    description:
      'Asegúrate de ingresar el correo con el cual recibiste la cuponera de Sácateun7',
  });
}

export default function LoginScreen({ onSuccess }) {
  const { data, error: unauthorized } = useSWR('/clients/profile', fetcher);
  const [modalVisible, setModalVisible] = useState(false);
  const [magicLinkId, setMagicLinkId] = useState(null);
  const [email, setEmail] = useState(null);
  const [creatingMagicLink, setCreatingMagicLink] = useState(false);

  const handleMagicLink = (magicLink) => {
    if (magicLink.id) {
      setMagicLinkId(magicLink.id);
      setModalVisible(true);
      return;
    }

    showGenericError();
  };

  const createMagicLink = (values) => {
    setCreatingMagicLink(true);
    setModalVisible(false);
    setMagicLinkId(null);
    fetchUrl({
      method: 'POST',
      url: '/passwordless_sessions',
      data: {
        passwordlessSession: {
          email: values.email,
          authenticableType: 'Client',
        },
      },
    })
      .then((magicLink) => {
        setEmail(values.email);
        handleMagicLink(magicLink);
        setCreatingMagicLink(false);
      })
      .catch(() => {
        showGenericError();
      });
  };

  if (unauthorized) {
    return (
      <HomeLayout>
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <Logo />
          <Typography.Title
            className="text-center"
            style={{ color: '#00B7A0' }}
          >
            Inicia sesión para agendar una clase
          </Typography.Title>
          <Spin spinning={creatingMagicLink}>
            <LoginForm onSuccess={createMagicLink} />
          </Spin>
        </Space>
        <Divider />
        <div className="text-center">
          <Link to="login">
            <Button type="link">Iniciar sesión con contraseña</Button>
          </Link>
        </div>
        <CheckerModal
          email={email}
          visible={modalVisible}
          magicLinkId={magicLinkId}
          onResend={createMagicLink}
        />
      </HomeLayout>
    );
  }

  if (data) {
    return (
      <HomeLayout>
        <Result
          icon={<img src="/sacateun7.png" alt="Logo de Sácateun7" />}
          title={
            <Typography.Title
              className="text-center"
              style={{ color: '#00B7A0' }}
            >
              Agenda tu clase
            </Typography.Title>
          }
          extra={
            <CallToActionButton onClick={onSuccess}>
              Comenzar
            </CallToActionButton>
          }
        />
      </HomeLayout>
    );
  }

  return <Spin />;
}

LoginScreen.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
