import PropTypes from 'prop-types';
import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

export default function HomeLayout({ children }) {
  return (
    <Layout
      style={{
        flexGrow: 1,
        justifyContent: 'center',
        backgroundColor: 'transparent',
        backgroundImage: "url('background.svg')",
      }}
    >
      <Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    ),
  ]).isRequired,
};
